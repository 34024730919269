import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", () => {
  gsap.utils.toArray(".fade-in-and-up").forEach((element) => {
    gsap.fromTo(
      element,
      {
        opacity: 0,
        y: 60,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.75,
        ease: "Power2.out",
        scrollTrigger: {
          trigger: element,
          start: "top 90%",
        },
      }
    );
  });

  // Hero animation

  const hero = document.querySelector("#hero");
  let heroTl = gsap.timeline();

  if (hero) {
    heroTl.from(hero.querySelector("h1"), {
      x: -60,
      autoAlpha: 0,
      duration: 1,
      ease: "power2.out",
    });

    heroTl.from(
      hero.querySelector(".subtitle-container"),
      {
        x: -60,
        autoAlpha: 0,
        duration: 1,
        ease: "power2.out",
      },
      "<+0.3"
    );

    heroTl.from(
      hero.querySelector("a.button"),
      {
        x: -60,
        autoAlpha: 0,
        duration: 1,
        ease: "power2.out",
      },
      "<+0.3"
    );

    heroTl.from(
      hero.querySelector(".image-container"),
      {
        x: 60,
        autoAlpha: 0,
        duration: 1,
        ease: "power2.out",
      },
      "<+0.6"
    );
  }

  // Service divider animation

  const servicesDividers = document.querySelectorAll(".service-divider");

  if (servicesDividers.length > 0) {
    servicesDividers.forEach((divider) => {
      const lines = divider.querySelectorAll(".line");
      const image = divider.querySelector("img");

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: divider,
          start: "top 90%",
        },
      });

      tl.from(lines[0], {
        x: 40,
        opacity: 0,
        duration: 1,
        ease: "power1.out",
      }).from(
        lines[1],
        { x: -40, autoAlpha: 0, duration: 1, ease: "power1.out" },
        "<"
      );

      tl.from(
        image,
        { autoAlpha: 0, duration: 1.5, ease: "power2.out" },
        "<+=0.25"
      );
    });
  }
  // Parallax image banner

  if (document.querySelector(".image-banner.parallax")) {
    gsap.to(".image-banner.parallax .image-container", {
      top: "-30%",
      scrollTrigger: {
        trigger: ".image-banner.parallax",
        start: "top bottom-=25%",
        end: "bottom top",
        scrub: 1,
      },
    });
  }

  // About More section image pin

  // let mm = gsap.matchMedia();

  // mm.add("(min-width: 768px)", () => {
  //   let headerHeight = document.querySelector("header").offsetHeight;
  //   const imageContainer = document.querySelector(
  //     "#about-more .image-container"
  //   );

  //   window.addEventListener("resize", () => {
  //     headerHeight = document.querySelector("header").offsetHeight;
  //     // ScrollTrigger.refresh();
  //   });

  //   ScrollTrigger.create({
  //     trigger: "#about-more .right-col",
  //     endTrigger: "#about-more .text-container",
  //     start: () => `top top+=${headerHeight}`,
  //     end: () =>
  //       `bottom bottom-=${
  //         window.innerHeight - imageContainer.offsetHeight - headerHeight
  //       }`,
  //     pin: true,
  //     pinSpacing: false,
  //     // markers: true,
  //   });
  // });
});
