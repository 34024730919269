import Swiper from "swiper/bundle";

if (document.querySelector(".testimonial-swiper")) {
  const swiper = new Swiper(".testimonial-swiper", {
    slidesPerView: 1,
    loop: true,
    speed: 750,
    autoHeight: true,
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
}
